@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Oswald:wght@500&display=swap');

:root{
  --save-red: #da291c;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body{
  width: 100%;
  height: 100%;

  background: white;
}

#root{
  display: flex;
  align-items: center;
  justify-content: center;
}

.App{
  width: 100%;
  height: 100%;
}

.header{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.App img{
  width: 200px;
}

.information{
  background: #222221;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0 50px 0;
}

.information h1{
  color: var(--save-red);
  font-family: Oswald;
  font-size: 45px;
}

.information div{
  color: white;
  font-family: Lato;
  margin-top: 5px;
  font-size: 18px;
}

.information  div:nth-child(2){
  font-weight: bold;
}

.canvas-card{
  width: 80%;
  max-width: 450px;
  margin: auto;
  position: relative;
  top: -40px;
  padding: 20px 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 10px 20px 50px #0000001A;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 300px;
}

.canvas-card > div{
  text-align: center;
}

.canvas-card h3{
  color: #707070;
  font-family: "Lato";
  font-weight: bolder;
  font-size: large;
}

.canvas-card h4,
.canvas-card h5,
.canvas-card h2{
  color: #707070;
  font-family: Lato;
  margin-top: 10px;
  font-size: 15px;
}

.canvas-card h2{
  font-size: large;
  color: #da291c;
}

.canvas-card canvas{
  width: 280px !important;
  height: 280px !important;
}

.canvas-card img{
  margin-bottom: 20px;
}

.canvas-card h3:first-child{
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 5px;
  border-bottom: 2px solid rgba(112, 112, 112, 0.20);
}

.canvas-card a{
  display: flex;
  margin-top: 20px;
}

.canvas-card h3:nth-child(3){
  width: 100%;
  text-align: center;
  padding-top: 15px;
  margin-top: 5px;
  border-top: 2px solid rgba(112, 112, 112, 0.20);
}

.date{
  text-align: center;
}